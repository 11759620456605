<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="7" class="d-none d-lg-flex align-items-center p-0 only-desktop">
        <div class="w-100 h-100 d-lg-flex bg-login">
          <div class="h-100 d-lg-flex flex-column align-items-start justify-content-between p-3">
            <futuro-logo-and-text height="150" dark />
            <h1 class="login-light-title">
              Seja bem-vindo à maior plataforma de planejadores financeiros autônomos do Brasil!
            </h1>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col cols="12" lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-row class="align-items-center justify-content-center pb-2">
            <b-col cols="auto">
              <futuro-text width="150" />
            </b-col>
          </b-row>
          <b-card-title class="mb-1 font-weight-bold" title-tag="h1">
            Login
          </b-card-title>
          <b-card-text class="mb-2">
            Para acessar sua conta, digite seu e-mail e senha no formulário abaixo:
          </b-card-text>

          <!-- form -->
          <b-form @submit.prevent="onSubmit">
            <!-- email -->
            <b-form-group label="E-mail" label-for="login-email" class="mb-1">
              <b-form-input id="login-email" v-model="email" name="login-email" placeholder="E-mail"
                :class="{ 'is-invalid': v$.email.$error }" />
              <div class="invalid-feedback">
                <span v-if="v$.email.$invalid">
                  Você deve informar um email válido
                </span>
              </div>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group class="mb-1">
              <div class="d-flex justify-content-between">
                <label for="login-password">Password</label>
                <b-link :to="{ name: 'account-forgot-password' }">
                  <small>Esqueceu sua senha?</small>
                </b-link>
              </div>
              <b-input-group class="input-group-merge">
                <b-form-input id="login-password" v-model="password" class="form-control-merge"
                  :type="passwordFieldType" :class="{ 'is-invalid': v$.password.$error }" name="login-password"
                  placeholder="Senha" />
                <b-input-group-append is-text>
                  <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                </b-input-group-append>
              </b-input-group>
              <small v-if="v$.password.$error && v$.email.$invalid" class="text-danger">
                Você deve informar uma senha válida com pelo menos 4 caracteres
              </small>
            </b-form-group>

            <b-form-group class="mb-1">
              <b-form-checkbox
                id="remember-me"
                v-model="rememberMe"
              >
                Lembrar minha senha
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <div v-if="loginError" class="text-danger mb-1">
              Suas credenciais são inválidas. Verifique seu e-mail e senha e tente novamente. Se o erro persistir, entre
              em contato com o setor de TI.
            </div>
            <b-button type="submit" variant="primary" block :disabled="loading">
              {{ loading? 'Aguarde...': 'Entrar' }}
            </b-button>
          </b-form>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center mt-3">
            <b-button variant="instagram" href="https://www.instagram.com/futuroconsultoriafinanceira/" target="_blank">
              <feather-icon icon="InstagramIcon" />
            </b-button>
            <b-button variant="linkedin" href="https://br.linkedin.com/in/futuro-consultoria-financeira-57519a208"
              target="_blank">
              <feather-icon icon="LinkedinIcon" />
            </b-button>
            <b-button variant="youtube" href="https://www.youtube.com/channel/UC27mBO9v7Y-d-_aKIfHePdQ" target="_blank">
              <feather-icon icon="YoutubeIcon" />
            </b-button>
            <b-button variant="facebook" href="https://www.facebook.com/futuroconsultoriafinanceira" target="_blank">
              <feather-icon icon="FacebookIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import FuturoLogoAndText from '@core/layouts/components/LogoAndText.vue'
import FuturoLogo from '@core/layouts/components/Logo.vue'
import FuturoText from '@core/layouts/components/LogoText.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import * as types from '../store/types'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    FuturoLogoAndText,
    FuturoLogo,
    FuturoText
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      loading: false,
      loginError: false
    }
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      email: { required, email },
      password: {
        required,
        minLength(value) {
          return value.length >= 4;
        }
      },
      rememberMe: {}
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions({
      login: types.LOGIN,
    }),
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return
      this.loading = true
      this.loginError = false
      const {
        email,
        password,
        rememberMe
      } = this
      this.login({ email, password, rememberMe })
        .catch(err => {
          this.loginError = true
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.bg-login {
  background-image: url('https://futurogfp-assets.s3.sa-east-1.amazonaws.com/futuro_background.webp');
  background-size: cover;
}
.login-light-title {
  color: #fff;
}
@media (max-width:1024px) {
  .only-desktop {
    display: none;
  }
}
</style>
